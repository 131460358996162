













































































































































































import Vue from "vue";
import VModal from "vue-js-modal";
import { createNamespacedHelpers } from "vuex";
import Vuelidate from "vuelidate";
import { required, email, requiredIf } from "vuelidate/lib/validators";

import { RoleType } from "@/proto/profile/all_pb";
import { UserRole, InvitedUser } from "@/types";
import { UserState } from "@/store/modules";
import { SvgIcon, Dropdown, CustomInput, Loader } from "@components";
import { getRoles } from "@/utils/common";

const userModule = createNamespacedHelpers("user");

Vue.use(VModal);
Vue.use(Vuelidate);

export default Vue.extend({
  name: "InviteUser",

  components: {
    SvgIcon,
    Loader,
    Dropdown,
    CustomInput,
  },

  data() {
    return {
      error: "",
      loading: false,
      corporateID: "",
      firstName: "",
      lastName: "",
      email: "",
      role: 0 as UserRole,
      roles: [] as any,
      cifNumber: "",
      phone: "",
      companyName: "",
    };
  },

  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
    },
    cifNumber: {
      required: requiredIf("isCreatingNewOrg"),
    },
    companyName: {
      required: requiredIf("isCreatingNewOrg"),
    },
  },

  computed: {
    ...userModule.mapState({
      user(state: UserState) {
        return state.userInfo;
      },
    }),

    userLoaded(): boolean {
      return !!this.user.firstName && !!this.user.lastName;
    },

    sendButtonText(): string {
      return this.loading ? "Sending User Invite" : "Send Invite";
    },

    corporateRoles(): UserRole[] {
      return [RoleType.ACCOUNTADMINISTRATOR, RoleType.MAKER, RoleType.CHECKER];
    },

    bankRoles(): UserRole[] {
      return [RoleType.BANKADMINISTRATOR, RoleType.CONTENTADMIN];
    },

    isBankUser(): boolean {
      return this.user.role === RoleType.BANKADMINISTRATOR;
    },

    isBankRole(): boolean {
      return !!this.bankRoles.find((role) => role === this.role);
    },

    isCorporateRole(): boolean {
      return !!this.corporateRoles.find((role) => role === this.role);
    },

    isCreatingNewOrg(): boolean {
      return this.isBankUser && this.isCorporateRole;
    },
  },

  methods: {
    ...userModule.mapActions(["createUser"]),

    showSuccessModal() {
      this.$modal.show("success-modal");
    },

    hideSuccessModal() {
      this.$modal.hide("success-modal");
    },

    generatePayload(): InvitedUser {
      const payload: InvitedUser = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        role: this.role,
        phone: this.phone,
      };

      if (this.isBankRole) {
        return payload;
      }

      // if is Corporate Role
      return {
        ...payload,
        cifNumber: this.cifNumber,
        corporateID: this.corporateID,
        companyName: this.companyName,
      };
    },

    initRoles() {
      const { role } = this.user;
      let roles: any = getRoles();

      roles = Object.keys(roles).map((role) => ({
        label: role,
        value: roles[role],
      }));

      const allowedRoles = roles.filter((userRole: any) => {
        let allowedRoles = [] as any;

        if (role === RoleType.BANKADMINISTRATOR) {
          allowedRoles = [...this.bankRoles, RoleType.ACCOUNTADMINISTRATOR];
        } else if (role === RoleType.ACCOUNTADMINISTRATOR) {
          allowedRoles = this.corporateRoles;
        } else if (role === RoleType.UNKNOWNROLETYPE) {
          allowedRoles = [RoleType.BANKADMINISTRATOR];
        } else {
          return false;
        }

        return allowedRoles.find((role: UserRole) => role === userRole.value);
      });

      this.roles = allowedRoles;

      if (role !== RoleType.UNKNOWNROLETYPE) {
        this.role = role;
      }
    },

    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.loading = true;

      try {
        const payload = this.generatePayload();
        await this.createUser(payload);
        this.showSuccessModal();
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.initRoles();
  },

  watch: {
    user: {
      handler() {
        if (this.userLoaded) {
          this.initRoles();
        }
      },
    },
  },
});
